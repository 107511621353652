import {
  Box,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Typography,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import filicon from "../../../images/filter-new.png";

import SearchIcon from "@mui/icons-material/Search";
import addicon from "../../../images/addicon.png";
import { useDispatch, useSelector } from "react-redux";
import { getDepartmentList } from "../../../redux/action";
import {
  DELETE_DEPARTMENT_URL,
  GET_DEPARTMENT_LIST_URL,
} from "../../common/ApiUrl";
import { CustomMessage, authHeader } from "../../common/mainfunctions";
import API from "../../common/API";
import { useSnackbar } from "notistack";
import closeIcon from "../../../images/close1.png";
import AddNewDepartment from "../../organizationadd/AddNewDepartment";
import OrgDepartmentFilter from "../Filters/OrgDepartmentFilter";
import CustomLoader from "../../common/CustomLoader";
import clrfilter from "../../../images/clearfilter.svg";
import PublishIcon from "@mui/icons-material/Publish";
import importicon from "../../../images/import.jpg";
import OrgDepartmentImport from "../Filters/OrgDepartmentImport";
export default function OrganizationDepartment(props) {
  const [open, setOpen] = useState(false);
  // const depList = useSelector((list) => list.DepartmentReducer);
  const { data = [], getOrgDetails, orgid } = props;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState();
  const [openfil, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState(null);
  const [depList, setDepList] = useState();
  const [loading, setLoading] = useState(false);
  const [openDep, setOpenDep] = useState(false);
  const addlicense = () => {
    setOpen(!open);
  };
  const openFilter = (e) => {
    setSearch("");

    setOpenFilter(!openfil);
  };
  const openDepartment = (e) => {
    setSearch("");

    setOpenDep(!openDep);
  };
  const clearFilter = () => {
    setFilter();
  };
  const handleClose = () => {
    setOpen(false);
    getOrgDetails();
    getDepList()
    dispatch(getDepartmentList(orgid, search));
   
  };
  const getDepList = async () => {
    setLoading(true);
    const options = {
      method: "GET",
      headers: authHeader(),
      url: GET_DEPARTMENT_LIST_URL,
      params: {
        organizationId: orgid,
        search: search ? search : filter,
      },
    };
    try {
      const response = await API(options);
      const { status, data } = response.data;
      if (status == "Success") {
        setDepList(data.list);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDepList();
    // dispatch(getDepartmentList(orgid, search));
  }, [search, filter]);
  const setSearchFun = (e) => {
    setSearch(e.target.value);
  };
  const setSearchFuncall = () => {
    dispatch(getDepartmentList(orgid, search));
  };
  const handleDelete = async (e) => {
    const id = e.currentTarget.dataset.id;
    const config = {
      method: "DELETE",
      url: DELETE_DEPARTMENT_URL,
      headers: authHeader(),
      data: {
        departmentId: id,
      },
    };
    try {
      const response = await API(config);
      const { status, message } = response.data;
      if (status == "Success") {
        CustomMessage(message, "success", enqueueSnackbar);
        getOrgDetails();
        getDepList();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const callImport = () => {
    setOpenDep(!openDep);
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Grid
            sx={{ mt: 1.5, pl: 2 }}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={6}>
              <Typography
                className="acti-log"
                style={{
                  fontSize: "24px",
                  fontWeight: "500",
                  lineHeight: "50px",
                }}
              >
                Department List
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={openFilter}
                >
                  <img src={filicon} />
                </IconButton>
                {filter && (
                  <IconButton
                    aria-label="delete"
                    sx={{ padding: "10px" }}
                    size="small"
                    onClick={clearFilter}
                  >
                    <img src={clrfilter} />
                  </IconButton>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1} direction="row" justifyContent="flex-end">
                <Paper
                  sx={{
                    p: "2px 6px",
                    display: "flex",
                    alignItems: "center",
                    width: "-webkit-fill-available",
                    marginTop: "auto",
                    marginBottom: "auto",
                    height: "50px",
                    boxShadow: "none",
                    borderRadius: "5px",
                  }}
                >
                  <InputBase
                    sx={{ flex: 1 }}
                    placeholder="Search ..."
                    inputProps={{ "aria-label": "   search" }}
                    onChange={setSearchFun}
                    value={search}
                  />
                  <IconButton
                    type="submit"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    style={{
                      backgroundColor: "#FFAB00",
                      borderRadius: "10%",
                      color: "#fff",
                    }}
                    onClick={setSearchFuncall}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>

                <IconButton
                  type="submit"
                  sx={{ p: "12px" }}
                  aria-label="search"
                  style={{
                    backgroundColor: "#00A1F1",
                    borderRadius: "10%",
                    marginTop: "1px",
                    marginBottom: "4px",
                  }}
                  onClick={addlicense}
                >
                  <img src={addicon} style={{ color: "#FFFFFF" }} />
                </IconButton>
                <IconButton
                  type="submit"
                  sx={{ p: "12px" }}
                  aria-label="search"
                  style={{
                    backgroundColor: "#00A1F1",
                    borderRadius: "10%",
                    marginTop: "1px",
                    marginBottom: "4px",
                  }}
                  onClick={callImport}
                >
                  <PublishIcon sx={{ color: "#FFF" }} />
                </IconButton>
              </Stack>
            </Grid>
            {loading ? (
              <>
                <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                  <CustomLoader loading={loading} />
                </Grid>
              </>
            ) : depList && depList.length == 0 ? (
              <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                <Typography>No matching records found.</Typography>
              </Grid>
            ) : (
              <></>
            )}
            {!loading &&
              depList &&
              depList.map((rec, i) => {
                return (
                  <Box sx={{ ml: "6px" }}>
                    <Box
                      className="category-item"
                      key={rec.name}
                      sx={{
                        cursor: "pointer",
                        ml: "12px",
                        mt: "12px",
                        fontWeight: "500",
                      }}
                    >
                      {rec.name}
                      <IconButton
                        aria-label="delete"
                        size="small"
                        data-id={rec.id}
                        onClick={handleDelete}
                      >
                        <img src={closeIcon} alt={rec.name} />
                      </IconButton>
                    </Box>
                  </Box>
                );
              })}
          </Grid>
        </Box>
        {open && (
          <AddNewDepartment
            open={open}
            handleClose={handleClose}
            flag={true}
            orgid={orgid}
          />
        )}
        {openfil && (
          <OrgDepartmentFilter
            open={openfil}
            handleClose={openFilter}
            setFilter={setFilter}
            setSearch={setSearch}
          />
        )}
        {openDep && (
          <OrgDepartmentImport
            open={openDep}
            handleClose={openDepartment}
            setFilter={setFilter}
            setSearch={setSearch}
          />
        )}
      </Box>
    </>
  );
}
