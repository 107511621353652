import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Input,
  InputBase,
  Link,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Logo from "../images/logo.jpg";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import MailIcon from "../images/mail.svg";
import PassIcon from "../images/password.svg";
import { Stack } from "@mui/system";
import * as Yup from "yup";
import API from "./common/API";
import { useFormik } from "formik";
import {
  CODE_AUTH_FOR_USER_URL,
  DOMAIN_BY_EMAIL_ID_URL,
  LOGIN_URL,
  MICROSOFT_AUTHENTICATION_URL,
  SIGN_UP_URL,
  USER_PROFILE_URL,
} from "./common/ApiUrl";
import { CustomMessage, getDomain, getIntegrate } from "./common/mainfunctions";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import { DASHBOARD_ROUTE, SIGNIN_ROUTE } from "../constants/RoutePath";
import CustomLoader from "./common/CustomLoader";
import Facebook from "../images/facebook.svg";
import Twitter from "../images/twitter.svg";
import Linkedin from "../images/linkedin.svg";
import { useDispatch } from "react-redux";
import {
  MIC_AUTH_FLAG_TYPE,
  USER_DETAILS_FLAG,
} from "../redux/action/ActionType";
import { AzureAD, AuthenticationState } from "react-aad-msal";

import { authProvider } from "../authProvider";
import AuthentySuccess from "./common/AuthentySuccess";
import { alpha, styled } from "@mui/material/styles";
import { useEffect } from "react";
import Divider from "@mui/material/Divider";
import * as microsoftTeams from "@microsoft/teams-js";
import { getUserAuth, getUserDetails } from "../redux/action";
import microsoftLogo from "../images/Microsoft_icon.svg 1.svg";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
export default function SignupdomainOption() {
  const { enqueueSnackbar } = useSnackbar();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [miFlag, setMiFlag] = useState(false);
  const [info, setInfo] = useState();
  const [option, setOption] = useState("yes");
  const [optionDomain, setOptionDomain] = useState(option);
  const [authFlag, setAuthFlag] = useState(false);
  const [token1, setToken] = useState("");
  const [code, setCode] = useState("");
  const [upn, setUPN] = useState();
  const [actualToken, setActualToken] = useState("");
  const [siloading, setSILoading] = useState(false);
  const [siloading1, setSILoading1] = useState(false);
  const [mobFlag, setMobFlag] = useState(false);
  const [wayFlag, setWayFlag] = useState();
  const [sideFlag, setSideFlag] = useState(false);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const tokens = queryParams.get("tokens");
  console.log(tokens, "get token");
  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return (
      /android|iPhone|iPad|iPod/i.test(userAgent) ||
      window.location.href.includes("teams.microsoft.com")
    );
  };
  useEffect(() => {
    if (isMobile()) {
      setMobFlag(true);
      console.log("Running inside Microsoft Teams on Mobile");
      // Handle mobile-specific logic
    }
  }, []);
  useEffect(() => {
    if ((mobFlag == true || isMobile() == true) && tokens) {
      const receivedToken = tokens.split("::")[0];
      const code = tokens.split("::")[1];
      const base64Url = receivedToken.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const decodedData = JSON.parse(atob(base64));
      const userDisplayName = decodedData.preferred_username;
      // setAuthFlag(true)
      setToken(receivedToken);
      setUPN(userDisplayName);
      setCode(code);
      let wf = sessionStorage.getItem("WAY_FLAG");
      getSigninUrl(userDisplayName, receivedToken, wf);
    }
  }, [tokens]);

  useEffect(() => {
    // Check if the app is running inside Microsoft Teams
    if (window.location !== window.parent.location) {
      setSideFlag(true);
      localStorage.setItem("SIDE_FLAG", true);
      microsoftTeams.initialize();
    } else {
      console.log("App is running outside of Microsoft Teams");
      localStorage.setItem("SIDE_FLAG", false);
      setSideFlag(false);
    }
  }, []);

  const isInTeams = () => {
    return window.self !== window.top;
  };
  const handleLoginClick = (flag) => {
    console.log(flag);
    sessionStorage.setItem("WAY_FLAG", flag);
    setWayFlag(flag);
    if (isInTeams()) {
      // Initialize Microsoft Teams SDK only if inside Teams
      microsoftTeams.initialize((initErr) => {
        if (initErr) {
          console.error("Teams initialization error:", initErr);
          return;
        }
        console.log("Teams SDK initialized");

        // Now use Teams authentication flow
        microsoftTeams.authentication.authenticate({
          url: `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
            response_type=id_token%20code
            &scope=openid%20profile%20user.read%20offline_access%20ChatMessage.Send
            &client_id=${cliendid}
            &redirect_uri=${redirecturl}
            &state=12345
             &prompt=login 
            &nonce=b3d7352f-1915-45a7-9460-946a06e3341e
            &client_info=1
            &response_mode=fragment`,
          width: 500,
          height: 600,
          successCallback: (result) => {
            console.log("Authentication successful:", result);
            // Handle the result (e.g., token)
          },
          failureCallback: (reason) => {
            console.error("Authentication failed:", reason);
          },
        });
      });
    } else if (mobFlag === true) {
      // Mobile flow: redirect-based authentication
      window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
        response_type=id_token%20code
        &scope=openid%20profile%20user.read%20offline_access%20ChatMessage.Send
        &client_id=${cliendid}
        &redirect_uri=${redirecturl}
        &state=12345
        &nonce=b3d7352f-1915-45a7-9460-946a06e3341e
        &client_info=1
        &response_mode=fragment`;
    } else {
      // Non-Teams desktop/web browser popup
      const popup = window.open("", "_blank", "width=500,height=600");

      // Set up a listener for postMessage
      window.addEventListener("message", (event) => {
        // Check the origin of the sender
        if (event.origin === "http://localhost:3000/") {
          // if (
          //   event.origin === "https://portal.devo365toolkit.com/"
          // ) {
          // Handle the message data
          const { token, type } = event.data;

          // Close the popup window if needed
          popup.close();

          // Do something with the token, e.g., log in the user
          // console.log("Received token:", token);
        }
      });

      popup.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
          response_type=id_token%20code
          &scope=openid%20profile%20user.read%20offline_access%20ChatMessage.Send
          &client_id=${cliendid}
          &redirect_uri=${redirecturl}
          &state=12345
          &nonce=b3d7352f-1915-45a7-9460-946a06e3341e
          &client_info=1
          &x-client-SKU=MSAL.JS
          &x-client-Ver=1.4.18
          &client-request-id=c7a53cff-9437-419b-b76d-d741fb322c51
          &response_mode=fragment`;
    }
  };

  let cliendid = process.env.REACT_APP_CLIENT_ID;
  let redirecturl = process.env.REACT_APP_REDIRECT_URI;
  let defaultDomain = process.env.REACT_APP_DOMAIN;
  const handleOption = (e) => {
    setOption(e.target.value);
    setOptionDomain(e.target.value);
  };
  const handleDomain = (e) => {
    if (e.target.value == "no") {
      setValues({ ...values, domainName: "", subdomain: "" });
    } else {
      if (token1) {
        let domain = upn.split("@")[1];
        domain = domain.split(".")[0];
        setValues({
          ...values,
          domainName: domain,
          subdomain: domain.toLowerCase(),
        });
      }
    }
    setOptionDomain(e.target.value);
  };

  useEffect(() => {
    if (option == "no") {
      setValues({ ...values, domainName: "", subdomain: "" });
    } else if (option == "yes") {
      if (token1) {
        let domain = upn.split("@")[1];
        domain = domain.split(".")[0];

        setValues({
          ...values,
          domainName: domain,
          subdomain: domain.toLowerCase(),
        });
      }
    }
  }, [option, token1]);
  const infoSetFun = (e) => {
    setInfo(e);
  };
  const validationSchema = Yup.object({
    domainName: Yup.string().trim().required("Company name is required"),
    subdomain: Yup.string().required("Domain is required"),
  });
  const chkMic = () => {
    setMiFlag(true);
    dispatch({
      type: MIC_AUTH_FLAG_TYPE,
      payload: true,
    });
  };
  const continueButton = document.getElementById("continue-button");
  const signupFun = async (values) => {
    setLoading(true);
    const { emailId, firstName, lastName, domainName, subdomain } = values;
    const config = {
      method: "POST",
      url: SIGN_UP_URL,
      data: {
        firstName: firstName,
        lastName: lastName,
        emailId: emailId,
        companyName: domainName,
        subDomain: subdomain,
        authToken: token1,
      },
    };
    try {
      const response = await API(config);
      const { status, data, token } = response.data;
      // let auth
      if (status == "Success") {
        CustomMessage("Signup successfull", "success", enqueueSnackbar);
        const options = {
          method: "GET",
          headers: { Authorization: token },
          url: CODE_AUTH_FOR_USER_URL,
          params: {
            tokenCode: code,
          },
        };
        try {
          const resp = await API(options);
          const protocol = window.location.protocol;
          let domain = window.location.hostname.replace(
            `${values.subdomain}.`,
            ""
          );
  
          let signinURL;
          if (domain.includes(".")) {
            domain = domain.split(".")[1];
          }
          if (domain == "localhost") {
            localStorage.setItem(
              "CURRENT",
              `${protocol}//${subdomain}.${domain}:3000`
            );
            signinURL = `${protocol}//${subdomain}.${domain}:3000/signin?tokens= ${encodeURIComponent(actualToken)}`;
          } else {
            // signinURL = `${protocol}//${subdomain}.devhrassist.app/signin?tokens= ${encodeURIComponent(actualToken)}`;
           
            // localStorage.setItem(
            //   "CURRENT",
            //   `${protocol}//${subdomain}.devhrassist.app`
            // );
            signinURL = `${protocol}//${subdomain}.hrassist.app/signin?tokens=${actualToken}`;
            localStorage.setItem(
              "CURRENT",
              `${protocol}//${subdomain}.hrassist.app`
            );
          }
        getSigninUrl(upn, token1, 'signin');

          console.log(resp);
        } catch (error) {
          console.error(error);
        }

      

        // localStorage.setItem('newWin',1)

        // continueButton.addEventListener('click', function() {
        // window.open(signinURL, "_blank");
        // });
        // window.open(signinURL, "_self");

      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        CustomMessage(data.message, "error", enqueueSnackbar);
      }
    } finally {
      setLoading(false);
    }
  };
  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    setErrors,
    touched,
  } = useFormik({
    initialValues: {
      domainName: "",
      subdomain: "",
      firstName: "",
      lastName: "",
      emailId: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      signupFun(values);
    },
  });
  const passFun = () => {
    setToggle(!toggle);
  };

  const chkSignup = () => {
    window.open("https://signup.live.com/signup");
  };
  const getMicAuthen = async (name, tokens, subdomain) => {
    setSILoading(true);
    const base64Url = tokens.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const decodedData = JSON.parse(atob(base64));
    const userDisplayName = decodedData.preferred_username;
    let us = decodedData.name;
    const nameSplit = decodedData.name.split(" "); // Split by spaces

    let formattedName;

    if (nameSplit.length > 1) {
      // More than one word (e.g., "Devo365tc3 Admin")
      formattedName = nameSplit.slice(1).join(" "); // Join everything after the first part
    } else {
      // Only one word (e.g., "Devo365tc3")
      formattedName = name; // Use the original name as-is
    }
    const config = {
      method: "POST",
      url: MICROSOFT_AUTHENTICATION_URL,
      data: {
        emailId: userDisplayName,
        authToken: token1 || tokens,
        subDomain: subdomain,
        firstName: decodedData.name.split(" ")[0],
        // lastName: userName.split(" ")[1],
        lastName: formattedName,
      },
    };
    try {
      const response = await API(config);
      const { status, data, token, message } = response.data;
      const { role, userId, subDomain } = data;
      if (status == "Success") {
        localStorage.setItem("AUTH_TOKEN", token);
        document.cookie = `authToken=${token}; path=/;`;

        localStorage.setItem("SIDE_FLAG", sideFlag);
        localStorage.setItem("ROLE", role);
        localStorage.setItem("USER_ID", userId);
        let dm = "/".concat(subDomain);
        sessionStorage.setItem("DOMAIN", dm);
        localStorage.setItem("SubDomain", dm);
        const options1 = {
          method: "GET",
          headers: { Authorization: token },
          url: USER_PROFILE_URL,
        };
        try {
          const response = await API(options1);
          if (response.status === 200) {
            const { status, data } = response.data;
            localStorage.setItem("ORGID", data.organizationId);
            console.log("role chk", role);
            if (
              role == "Admin" &&
              (data.msIntegrateFlag == parseInt(0) ||
                data.msIntegrateFlag == null)
            ) {
              navigation("/organization");
            } else {
              navigation("/dashboard");
            }
            dispatch({
              type: USER_DETAILS_FLAG,
              payload: data,
            });
          }
        } catch (error) {
          if (error.response) {
            let { data } = error.response;
          }
        }
        dispatch(getUserDetails());
        if (role != "Admin") {
          dispatch(getUserAuth(code));
        }
        CustomMessage("Login successfull", "success", enqueueSnackbar);

        const options = {
          method: "GET",
          headers: { Authorization: token },
          url: CODE_AUTH_FOR_USER_URL,
          params: {
            tokenCode: code,
          },
        };
        try {
          const resp = await API(options);
          console.log(resp);
        } catch (error) {
          console.error(error);
        }
        console.log("role", role, getIntegrate());
        setTimeout(() => {
          // if (role == "Admin" && (getIntegrate() == parseInt(0)||getIntegrate()==null)) {
          //   navigation("/organization");
          // } else {
          //   navigation("/dashboard");
          // }
        }, 1500);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        // localStorage.clear();
        CustomMessage(data.message, "error", enqueueSnackbar);
      }
    } finally {
      // navigation(ORGANIZATION_SETTING_ROUTE);
      setSILoading(false);
    }
  };
  const getSigninUrl = async (name, tokens, wf) => {
    setSILoading1(true);
    const config = {
      method: "GET",
      url: DOMAIN_BY_EMAIL_ID_URL,
      params: {
        emailId: name,
      },
    };
    try {
      const res = await API(config);
      const { data, status } = res.data;
      let subdomain;
      if (status == "Success") {
        if (wf == "signup") {
          CustomMessage(
            "You already have an account with this email. Please log in.",
            "error",
            enqueueSnackbar
          );
          // setAuthFlag(true)
        } else {
          subdomain = data.subDomain;
          const protocol = window.location.protocol;
          let domain = window.location.hostname.replace(`${subdomain}.`, "");

          let signinURL;
          if (domain.includes(".")) {
            domain = domain.split(".")[1];
          }
          if (domain == "localhost") {
            localStorage.setItem(
              "CURRENT",
              `${protocol}//${subdomain}.${domain}:3000`
            );
            signinURL = `${protocol}//${subdomain}.${domain}:3000/signin?tokens=${tokens}`;
          } else {
            // document.cookie = `userToken=${tokens}; path=/; domain=.devhrassist.app; samesite=None; secure; expires=${new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toUTCString()}`;
            // document.cookie = `userdomain=${subdomain}; path=/; domain=.devhrassist.app; samesite=None; secure; expires=${new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toUTCString()}`;
            
// dev
            // document.cookie = `userToken=${tokens}; path=/; domain=.devhrassist.app; samesite=lax`;
            // document.cookie = `userdomain=${subdomain}; path=/; domain=.devhrassist.app; samesite=lax`;
            // signinURL = `${protocol}//${subdomain}.devhrassist.app/signin?tokens=${tokens}`;
            // localStorage.setItem(
            //   "CURRENT",
            //   `${protocol}//${subdomain}.devhrassist.app`
            // );
            // prod
//             document.cookie = `userToken=${tokens}; path=/; domain=.hrassist.app; samesite=none; secure`;
// document.cookie = `userdomain=${subdomain}; path=/; domain=.hrassist.app; samesite=none; secure`;
const expiryDate = new Date();
expiryDate.setTime(expiryDate.getTime() + (7 * 24 * 60 * 60 * 1000)); // 7 days from now
document.cookie = `userToken=${tokens}; path=/; domain=.hrassist.app; samesite=lax; expires=${expiryDate.toUTCString()}; secure`;
document.cookie = `userdomain=${subdomain}; path=/; domain=.hrassist.app; samesite=lax; expires=${expiryDate.toUTCString()}; secure`;

            signinURL = `${protocol}//${subdomain}.hrassist.app/signin?tokens=${tokens}`;
            localStorage.setItem(
              "CURRENT",
              `${protocol}//${subdomain}.hrassist.app`
            );
            localStorage.setItem('usr_tok',tokens)
            localStorage.setItem('usr_dom',subdomain)
          }


          window.open(signinURL, "_self");
        }
        // getMicAuthen(name,tokens,subdomain)
      }
    } catch (error) {
      console.log(error);
      let wf = sessionStorage.getItem("WAY_FLAG");

      if(wf=='signup'){
      setAuthFlag(true);
      }else{
        CustomMessage(
          "No account found with this email. Please sign up.",
          "error",
          enqueueSnackbar
        );
      }
    } finally {
      setSILoading1(false);
    }
  };
  // Listen for messages from the redirect.html page
  useEffect(() => {
    const handleMessage = (event) => {
      const { data } = event;
      if (data && data.type == "error") {
        console.log("error requester");
        const popup = window.open("", "_blank", "width=500,height=600");

        // Set up a listener for postMessage
        window.addEventListener("message", (event) => {
          // Check the origin of the sender
          if (event.origin === "http://localhost:3000/") {
            // if (
            //   event.origin === "https://portal.devo365toolkit.com/"
            // ) {
            // Handle the message data
            const { token, type } = event.data;

            // Close the popup window if needed
            popup.close();

            // Do something with the token, e.g., log in the user
            // console.log("Received token:", token);
          }
        });

        popup.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
                          response_type=id_token%20code
                          &scope=openid%20profile%20user.read%20offline_access%20ChatMessage.Send
                          &client_id=${cliendid}
                          &redirect_uri=${redirecturl}
                          &state=12345
                          &nonce=b3d7352f-1915-45a7-9460-946a06e3341e
                          &client_info=1
                          &x-client-SKU=MSAL.JS
                          &x-client-Ver=1.4.18
                          &client-request-id=c7a53cff-9437-419b-b76d-d741fb322c51
                          &response_mode=fragment`;
      }
      if (data && data.type === "tokenMessage") {
        const atok = data.token;
        setActualToken(atok);
        // Assuming that the message contains the token
        const receivedToken = data.token.split("::")[0];
        const code = data.token.split("::")[1];
        const base64Url = receivedToken.split(".")[1];
        // setActualToken(code);

        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const decodedData = JSON.parse(atob(base64));
        const userDisplayName = decodedData.preferred_username;
        // setAuthFlag(true)
        setToken(receivedToken);
        setUPN(userDisplayName);
        setCode(code);
        let namesplit = decodedData.name.split(" ");
        setValues({
          ...values,
          firstName: namesplit[0],
          lastName: namesplit[1],
          emailId: userDisplayName,
        });
        // Respond back to the redirect.html page with a confirmation message
        event.source.postMessage({ type: "tokenReceived" }, event.origin);
        let wf = sessionStorage.getItem("WAY_FLAG");

        // if(wf=="signup"){
        //   CustomMessage("You already have an account with this email. Please log in.", "error", enqueueSnackbar);
        // }
        // else{
        getSigninUrl(userDisplayName, receivedToken, wf);
        // }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  return (
    <>
      <Box sx={{ flexGrow: 1 }} component={"form"}>
        <Grid container className="main" spacing={{ xs: 2, md: 3 }}>
          <Grid
            sx={{ display: { xs: "none", sm: "none", md: "unset" } }}
            item
            xs={11}
            md={6}
          >
            <Box className="bg-image">
              <Typography className="signup-title" variant="h2">
                HR Assist
              </Typography>
              {/* <Typography className="login-text" variant="body2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </Typography> */}
              <Stack
                className="social-icons"
                sx={{ pt: "20px" }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Typography className="copyright" variant="h4">
                  HR Assist Toolkit © 2024
                </Typography>
                <Box>
                  {/* <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <img src={Facebook} />
                    <img src={Twitter} />
                    <img src={Linkedin} />
                  </Stack> */}
                </Box>
              </Stack>
            </Box>
          </Grid>
          {authFlag == false ? (
            <Grid item xs={12} md={6}>
              <Box className="form-right">
                <img
                  className="signup-form-img"
                  style={{ paddingTop: "120px" }}
                  src={""}
                />
                <Typography className="welcome-text">
                  Create an Account
                </Typography>
                <br />
                <Typography className="log-actions">
                  You will need to sign in to your Microsoft 365 Tenant using
                  Global Admin to Create an Account. You will be redirected to
                  Microsoft 365 Tenant page to Connect to your Global Admin
                  Account.
                </Typography>
                <br />
                <Typography className="log-actions1">
                  Do you want to use your Microsoft 365 Tenant name as your HR
                  Assist account name?
                </Typography>

                <Box
                  sx={{
                    textAlign: "left",
                    margin: "auto",
                    alignContent: "left",
                  }}
                >
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={option}
                      onChange={handleOption}
                    >
                      <FormControlLabel
                        className="log-bx"
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        className="log-bx"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>

                <p>
                  <div
                    className="bsk-container"
                    onClick={() => {
                      handleLoginClick("signup");
                    }}
                  >
                    <Button className="login-btn" size="medium">
                      {siloading && <CustomLoader loading={siloading} />}
                      <Typography className="signup-btn-text">
                        CREATE HR ASSIST ACCOUNT
                      </Typography>
                    </Button>
                  </div>
                  {/* <button onClick={handleLoginClick}>Test Login</button> */}
                </p>
                <br />
                <Divider>OR</Divider>
                <Typography className="log-actions">
                  Already have an account
                </Typography>

                <Button
                  onClick={() => {
                    handleLoginClick("signin");
                  }}
                  style={{ width: "100% !important" }}
                  className="wit-txt"
                >
                  {siloading && <CustomLoader loading={siloading} />}
                  <Typography
                    className="signin-btn-text"
                    style={{ width: "100% !important" }}
                  >
                    <div
                      className="microsoft-signin-button"
                      style={{ justifyContent: "center" }}
                    >
                      <img
                        src={microsoftLogo}
                        alt="Microsoft logo"
                        className="microsoft-logo"
                      />
                      <span>SIGN IN WITH MICROSOFT</span>
                    </div>
                  </Typography>
                </Button>

                <br />
                <Typography className="log-actions">
                  By continuing, you agree to the{" "}
                  <Link
                    href="https://www.intelligis.com/about/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Use
                  </Link>{" "}
                  and{" "}
                  <Link
                    href="https://www.intelligis.com/privacy-policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </Link>
                  .
                </Typography>
              </Box>
            </Grid>
          ) : (
            <Grid item xs={12} md={6}>
              <Box className="form-right">
                <img style={{ paddingTop: "40px" }} src={""} />
                <Typography
                  className="welcome-text"
                  variant="h5"
                  sx={{ paddingTop: "100px" }}
                >
                  Welcome to HR Assist Portal
                </Typography>
                <Typography className="login-action" variant="body2">
                  Would you like to match our subdomain to the existing
                </Typography>
                <Typography
                  className="login-action"
                  sx={{ mb: "20px" }}
                  variant="body2"
                >
                  onmicrosoft.com subdomain
                </Typography>
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                  margin: "auto",
                  alignContent: "center",
                }}
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={optionDomain}
                    onChange={handleDomain}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ textAlign: "center", margin: "30px" }}>
                <div class="bsk-container">
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <BootstrapInput
                      name="domainName"
                      id="domainName"
                      value={values.domainName}
                      disabled={optionDomain == "yes" ? true : false}
                      onChange={handleChange}
                      placeholder="Company Name"
                    />
                    <FormHelperText error={true}>
                      {errors.domainName && touched.domainName
                        ? errors.domainName
                        : ""}
                    </FormHelperText>
                  </FormControl>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ paddingTop: "10px" }}
                  >
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <BootstrapInput
                        name="subdomain"
                        id="subdomain"
                        value={values.subdomain}
                        disabled={optionDomain == "yes" ? true : false}
                        onChange={handleChange}
                        placeholder="Sub domain"
                      />
                      <FormHelperText error={true}>
                        {errors.subdomain && touched.subdomain
                          ? errors.subdomain
                          : ""}
                      </FormHelperText>
                    </FormControl>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography sx={{ paddingTop: "20px" }}>
                        {defaultDomain}
                      </Typography>
                    </Box>
                  </Stack>
                  <Button
                    className="login-btn"
                    size="medium"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    {loading && <CustomLoader loading={loading} />}
                    <Typography className="signup-btn-text">SIGN-UP</Typography>
                  </Button>
                </div>
              </Box>
            </Grid>
          )}
        </Grid>
        {/* {miFlag==true&&<AuthWorkPage/>} */}
        {/* {miFlag&&
        <AzureAD provider={authProvider}>
        <span>Only authenticated users can see me.</span>
      </AzureAD>
      } */}
      </Box>
    </>
  );
}
