import React, { useEffect, useState } from "react";
import AuthentySuccess from "./common/AuthentySuccess";
import { CustomMessage, authHeader } from "./common/mainfunctions";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Login from "./Login";
import { Typography } from "@mui/material";
import { authProvider } from "../authProvider";
import { AzureAD, AuthenticationState } from "react-aad-msal";

import API from "./common/API";
import { withMsal, useMsal } from "react-aad-msal";
import { PERMISSIONN_FLAG_UPDATE_URL } from "./common/ApiUrl";
export default function CopyRedirect(props) {
  const [siloading, setSILoading] = useState(false);
  const [token, setToken] = useState();
  const [flag, setFlag] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const para = useParams();
  const location = useLocation();
  const dynamicDomain = "devo365tc";
  const navigation = useNavigate();
  let dom = sessionStorage.getItem("CURRENT");
  const url = new URL(window.location.href);
  const code = url.searchParams.get("code");
  console.log(url, "url");
  let idToken;
const isMobile = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // return true
  return (
    /android|iPhone|iPad|iPod/i.test(userAgent) ||
    window.location.href.includes("teams.microsoft.com")
  );
};
function getCookie(name) {
  console.log(name,document.cookie)
  const value = `; ${document.cookie}`;
  console.log(value)
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

useEffect(() => {
  const qstr = window.location.search;
  const finqstr = qstr.slice(1).split("=")[0];

  const handleRedirect = () => {
    if (finqstr === "admin_consent") {
      if (window.opener) {
        window.opener.postMessage({ finqstr, type: "flagMessage" }, "*");
      }
      setTimeout(() => {
        window.localStorage.clear();
        window.close();
      }, 500);
    } else {
      const hash = window.location.hash.substring(1);
      const params = new URLSearchParams(hash);
      const token = params.get("id_token");
      const subdomain = getCookie("userdomain");

      if (token) {
        if (isMobile()) {
          localStorage.setItem("auth_token", token);
         //  window.location.href = "https://devhrassist.app/dashboard";
         let targetUrl
         if(subdomain){
            //  targetUrl = `https://${subdomain}.devhrassist.app/signin?tokens=${token}`;
             targetUrl = `https://${subdomain}.hrassist.app/signin?tokens=${token}`;
            window.location.href = targetUrl;
         }
            else{
            //  targetUrl = `https://devhrassist.app/signup?tokens=${token}`;
             targetUrl = `https://hrassist.app/signup?tokens=${token}`;
            window.location.href = targetUrl;

         }
          return;
        }

        if (window.opener) {
          window.opener.postMessage({ token, type: "tokenMessage" }, "*");
        }

        setTimeout(() => {
          clearLocalStorage();
          window.close();
        }, 500);
      } else {
        clearLocalStorage().then(() => {
         
          if (window.opener) {
            window.opener.postMessage({ token, type: "logout" }, "*");
          }
          window.close();
          if (isMobile()) {
           localStorage.clear()
            //  const targetUrl = `https://devhrassist.app/signup`;
             const targetUrl = `https://hrassist.app/signup`;
             window.location.href = targetUrl;
           return;
         }
        });
      }
    }
  };

  const clearLocalStorage = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        window.localStorage.clear();
        resolve();
      }, 500);
    });
  };

  if (isMobile()) {
   //  CustomMessage("Inside mobile", "success", enqueueSnackbar);
    handleRedirect();
  } else {
    handleRedirect();
  }
}, []);
  useEffect(() => {
    let qstr = window.location.search;
    console.log(qstr);
    let finqstr = qstr.slice(1).split("=")[0];
    if (finqstr == "admin_consent") {
      // flagUpdate();
      if (window.opener) {
        window.opener.postMessage({ finqstr, type: "flagMessage" }, "*");
      }
      setTimeout(() => {
        window.localStorage.clear();
        window.close();
      }, 500);
    }
  }, [window.location.search]);
  console.log(window.location.hash);

  useEffect(() => {
    var urlFragment = window.location.hash.substring(1);
    console.log(urlFragment);
    if (urlFragment == "error=access_denied&error_subcode=cancel&state=12345") {
      if (window.opener) {
        window.opener.postMessage({ token, type: "error" }, "*");
      }
    }
    // Parse the fragment into key-value pairs
    var fragmentParams = urlFragment.split("&").reduce(function (result, item) {
      var parts = item.split("=");
      result[parts[0]] = parts[1];
      return result;
    }, {});

    // Extract the code and id_token
    var code1 = fragmentParams["code"];
    var idToken1 = fragmentParams["id_token"];

    if (idToken1 && code1) {
      let token = idToken1.concat("::").concat(code1);
      // if (qstr) {
      //   let ftoken = token.concat("::").concat(finqstr);
      // }
      setToken(token);
    }
  }, [window.location.hash]);

  useEffect(() => {
    if (token) {
      console.log("token presenr", token);
      // getMicAuthen(token)
      localStorage.setItem('AUTH_TOKEN',token)
      if (window.opener) {
        window.opener.postMessage({ token, type: "tokenMessage" }, "*");
      }

      setTimeout(() => {
        setFlag(false);
        window.localStorage.clear();
        window.localStorage.removeItem(
          "msal.dd6fddc2-99f3-4cbe-a9cc-045a935dd24a.idtoken"
        );
        window.localStorage.removeItem(
          "msal.dd6fddc2-99f3-4cbe-a9cc-045a935dd24a.client.info"
        );
        window.localStorage.removeItem(
          '{"authority":"https://login.microsoftonline.com/c6c6b970-f0fc-4ed0-a162-dbeb6f3ccc41/","clientId":"dd6fddc2-99f3-4cbe-a9cc-045a935dd24a","homeAccountIdentifier":"NmU3NjdmZTktOTg0NC00OTk3LThmNDItZGZkNTNkZjNiNDI5.YzZjNmI5NzAtZjBmYy00ZWQwLWExNjItZGJlYjZmM2NjYzQx"}'
        );
        window.close();
      }, 500);
    } else {
      window.localStorage.clear();
      // Use a promise to wait for local storage to be cleared
      const clearLocalStoragePromise = new Promise((resolve) => {
        setTimeout(() => {
          window.localStorage.clear();
          resolve();
        }, 500);
      });

      // Navigate after local storage is cleared
      clearLocalStoragePromise.then(() => {
        setFlag(false);
        if (window.opener) {
          window.opener.postMessage({ token, type: "logout" }, "*");
        }
        window.close();

        // navigation(-3, { state: { key: "logout" } });
      });
    }
  }, [token, window.location.hash]);

  return (
    <>
      <AzureAD provider={authProvider}>
        {({ login, logout, authenticationState, error, accountInfo }) => {
          switch (authenticationState) {
            case AuthenticationState.Authenticated:
              {
                flag == false && logout();
              }
              return <></>;
            case AuthenticationState.Unauthenticated:
              {
                flag == false && logout();
              }
              return <></>;
          }
        }}
      </AzureAD>
      <Typography>redirecting</Typography>
      {/* {idToken&&
        <Login token={idToken}/>} */}
    </>
  );
}