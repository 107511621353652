import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { alpha, styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  Box,
  FormControl,
  FormHelperText,
  InputBase,
  MenuItem,
  Select,
} from "@mui/material";
import { useFormik } from "formik";
import {
  ADD_USER_TO_GROUP,
  ADD_USER_TO_GROUP_MEMBER,
  ALL_USERS_LIST_URL,
  USERS_LIST_FROM_AZURE_FOR_USERS_URL,
  USER_LISTS_URL,
} from "../common/ApiUrl";
import { CustomMessage, authHeader } from "../common/mainfunctions";
import API from "../common/API";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import gicon from "../../images/group.png";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { useEffect } from "react";
import { useState } from "react";
import CustomLoader from "../common/CustomLoader";
import * as Yup from "yup";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const BootstrapInputSel = styled(Select)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const initialValues = {
  userName: "User Name",
};
export default function AddMember(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { open, handleClose, gid } = props;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object({
    userName: Yup.string()
      .trim()
      .test("is-in-arr", "Invalid user", function (value) {
        console.log("value", value, items);
        const isValid = items.some((item) => item.name === value);

        return isValid;
      })
      .required("User name is required"),
  });
  const addMemberFun = async (values) => {
    setLoading(true);
    let uid;
    let upn;
    const fr = items.filter((r) => r.name == values.userName);
    console.log("fr", fr, values.userName, items, gid, fr[0].id);
    if (fr) {
      uid = fr[0].id;
      upn = fr[0].userPrincipalName;
    }
    const options = {
      method: "POST",
      url: ADD_USER_TO_GROUP_MEMBER,
      headers: authHeader(),
      data: {
        groupId: gid,
        userId: uid,
        userPrincipalName: upn,
      },
    };
    try {
      var apiResponse = await API(options);
      const { status, message } = apiResponse.data;
      if (status == "Success") {
        CustomMessage(message, "Success", enqueueSnackbar);
        handleClose();
      }
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        CustomMessage(message, "error", enqueueSnackbar);
      }
    } finally {
      setLoading(false);
    }
  };
  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    setErrors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      addMemberFun(values);
    },
  });
  const handleOnSearch = (string, results) => {
    setValues({ ...values, userName: string });
    getUsersList(string);
  };

  const handleOnHover = (result) => {
    // the item hovered
  };
  const handleOnSelect = (item) => {
    // the item selected
    // setValues(item)
    setValues({ ...values, userName: item.name });
  };
  const handleOnFocus = () => {
    console.log("Focused");
  };

  const formatResult = (item) => {
    console.log("item", item);
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
      </>
    );
  };
  const getUsersList = async (str) => {
    const options = {
      method: "GET",
      url: ALL_USERS_LIST_URL,
      headers: authHeader(),
      params: {
        search: str,
      },
    };
    try {
      var apiResponse = await API(options);
      const { status, data } = apiResponse.data;
      if (status === "Success") {
        let temp = data.value;
        let newColumns;
        if (temp) {
          newColumns = temp.map((item) => {
            const { displayName: name, ...rest } = item;
            return { name, ...rest };
          });
        }
        const sortedItems = newColumns.sort((a, b) =>
          a.name.replace(/[^a-zA-Z0-9]/g, "").toLowerCase().localeCompare(
            b.name.replace(/[^a-zA-Z0-9]/g, "").toLowerCase()
          )
        );
        setItems(sortedItems);
      }
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        CustomMessage(message, "error", enqueueSnackbar);
      }
    } finally {
      // setLoading(false);
    }
  };
  useEffect(() => {
    getUsersList();
  }, []);
  return (
    <div>
      <BootstrapDialog
        className="org-popup"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <Box style={{ textAlign: "center" }}>
            <img src={gicon} />
          </Box>
          <Box style={{ textAlign: "center" }}>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "16px",
                alignContent: "center",
              }}
            >
              Add New User
            </Typography>
            <form onSubmit={handleSubmit}>
              <FormControl
                style={{ marginTop: "10px" }}
                sx={{ width: "100%", minWidth: "500px" }}
                variant="standard"
              >
                <ReactSearchAutocomplete
                  name="email"
                  items={items}
                  fuseOptions={{shouldSort:false}}
                  onSearch={handleOnSearch}
                  onHover={handleOnHover}
                  onSelect={handleOnSelect}
                  onFocus={handleOnFocus}
                  autoFocus
                  showIcon={false}
                  placeholder="Write the user name"
                  styling={{
                    backgroundColor: "#F8F8F8",
                    borderRadius: "0px",
                    height: "64px",
                    zIndex: 2,
                  }}
                  formatResult={formatResult}
                />
                <FormHelperText error={true}>
                  {errors.userName && touched.userName ? errors.userName : ""}
                </FormHelperText>
              </FormControl>

              <Button
                type="submit"
                className="login-btn-group-bl"
                size="medium"
                onClick={handleSubmit}
              >
                {loading && <CustomLoader loading={loading} />}
                SUBMIT
              </Button>
              <Button
                className="login-btn-group"
                size="medium"
                onClick={handleClose}
              >
                CANCEL
              </Button>
            </form>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
