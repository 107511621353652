import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  ACCOUNT_SETTING_ROUTE,
  DASHBOARD_ROUTE,
  ORGANIZATION_SETTING_ROUTE,
  SIGNIN_ROUTE,
} from "../../constants/RoutePath";
import moment from "moment";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export function PublicRoute({ children }) {
  // const token=useSelector((t)=>t.AuthTokenSaveReducer)
  const location = useLocation();
  let pathName = location.pathname;
  let arr = pathName.toString().split("/");
  let currentPath = arr[1];
  let cpa = "/".concat(currentPath);
  if (sessionStorage.getItem("DOMAIN") == "") {
    sessionStorage.setItem("DOMAIN", cpa);
  }
  const token = localStorage.getItem("AUTH_TOKEN");
  let dm = sessionStorage.getItem("CURRENT");
  let dd;
  console.log('gets',getRole(),getIntegrate())
  if (dm) {
    dd = dm;
  } else {
    dd = cpa;
  }

  return !token ? (
    children
  ) : location.pathname.includes("approvenotification") ||
    location.pathname.includes("rejectnotification") ? (
    //  ||location.pathname.includes("mailbox")
    children
  ) : getIntegrate() == 0 &&getRole()=='Admin'? (
    <Navigate to={"/organization"} />
  ) : (
    <Navigate to={"/dashboard"} />
  );
}
export function PrivateRouteTeams({ children }) {
  const location1 = useLocation();
  const history = useNavigate();

  let pathName = location1.pathname;
  let arr = pathName.toString().split("/");
  let currentPath = arr[1];
  let cpa = "/".concat(currentPath);

  if (sessionStorage.getItem("DOMAIN") === "") {
    sessionStorage.setItem("DOMAIN", cpa);
  }

  const token = localStorage.getItem("AUTH_TOKEN");
  const location = window.location.href;
  let dm = sessionStorage.getItem("CURRENT");
  let dd = dm ? dm : cpa;

  useEffect(() => {
    // Check if the user is logged out across tabs
    const handleStorageChange = () => {
      const newToken = localStorage.getItem("AUTH_TOKEN");
      if (!newToken) {
        // Manually set the full URL to redirect to localhost:3000/signup
        // window.location.href = "http://localhost:3000/signup";
        // window.location.href = "https://devhrassist.app/signup";
        window.location.href = "https://hrassist.app/signup";
      }
    };

    // Listen to localStorage changes (for when logout occurs in other tabs)
    window.addEventListener("storage", handleStorageChange);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return token ? children : <Navigate to="http://localhost:3000/signup" />;
}
export function PrivateRoute({ children }) {
  const location1 = useLocation();
  const history = useNavigate();
  const sideFlag = localStorage.getItem("SIDE_FLAG");

  let pathName = location1.pathname;
  let arr = pathName.toString().split("/");
  let currentPath = arr[1];
  let cpa = "/".concat(currentPath);

  if (sessionStorage.getItem("DOMAIN") === "") {
    sessionStorage.setItem("DOMAIN", cpa);
  }

  const token = localStorage.getItem("AUTH_TOKEN");
  const location = window.location.href;
  let dm = sessionStorage.getItem("CURRENT");
  let dd = dm ? dm : cpa;

  useEffect(() => {
    if (sideFlag=='true') {
      // Check if the user is logged out across tabs
      const handleStorageChange = () => {
        const newToken = localStorage.getItem("AUTH_TOKEN");
        if (!newToken) {
          // Redirect to specific signup page when sideFlag is true
          // window.location.href = "https://devhrassist.app/signup";
          window.location.href = "https://hrassist.app/signup";
        }
      };

      // Listen to localStorage changes (for when logout occurs in other tabs)
      window.addEventListener("storage", handleStorageChange);

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener("storage", handleStorageChange);
      };
    }
  }, [sideFlag]);

  // Handle conditional navigation based on sideFlag
  if (sideFlag==false) {
    return token ? children : <Navigate to={"/signin"} state={location} />;
  }

  // Default return if sideFlag is true but token is missing
  return token ? children : <Navigate to="/signin" />;
}
export function authHeader() {
  const token = localStorage.getItem("AUTH_TOKEN");
  return {
    Authorization: token,
  };
}
export function authHeaderchk() {
  const token = localStorage.getItem("AUTH_TOKEN");
  return  token
  
}
export function getTok() {
  const token = localStorage.getItem("tok");
  return token
  
}
export const CustomMessage = (message, type, enqueueSnackbar) => {
  enqueueSnackbar(message, {
    variant: type,
    persist: ["success", "info", "error"].includes(type) ? false : true,
    autoHideDuration: ["success", "info", "error"].includes(type) && 4000,
  });
};
export const CustomMessageToast = (message, type, enqueueSnackbar) => {
  enqueueSnackbar(message, {
    variant: type,
    persist: ["success", "info", "error"].includes(type) ? false : true,
    autoHideDuration: ["success", "info", "error"].includes(type) && 8000,
  });
};
export const NumberOfDays = (cd) => {
  const td = moment(new Date());
  let nod = td.diff(cd, "days");
  if (nod > 1) {
    return `${nod} days`;
  } else {
    return `${nod} day`;
  }
};

export const getRole = () => {
  const role = localStorage.getItem("ROLE");
  return role;
};
export const getOrgId = () => {
  const oid = localStorage.getItem("ORGID");
  return oid;
};
export const getDomain = () => {
  const domain = sessionStorage.getItem("DOMAIN");
  return domain;
};
export const getTentant = () => {
  const tid = localStorage.getItem("TID");
  return tid;
};
// export function getTok() {
//   const token = localStorage.getItem("tok");
//   return token
  
// }
export const getIntegrate = () => {
  const inflag = localStorage.getItem("MSFLAG");
  return null;
};
export const getSideFlag = () => {
  const sideFlag = localStorage.getItem("SIDE_FLAG");
  // return "true";
  return sideFlag
};
export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};
export function clearCookie(name) {
  document.cookie = `${name}=; path=/; domain=.devhrassist.app; expires=Thu, 01 Jan 1970 00:00:00 UTC; samesite=lax`;
}